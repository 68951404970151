import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ForecastingList = ({remarks,NotVaccinatedCount}) => {



  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Reason for not vaccinating</TableCell>
            <TableCell>Confirm missed vaccine count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {remarks.map((remarks, index) => (
            <TableRow key={index} sx={index % 2 === 0 ? { bgcolor: '#f5f5f5' } : {}}>
              <TableCell>{remarks}</TableCell>
              <TableCell>{NotVaccinatedCount[index]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ForecastingList;
