import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ForecastingList = ({data}) => {

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Week</TableCell>
            <TableCell>Year</TableCell>
            <TableCell>Month</TableCell>
            <TableCell>Day</TableCell>
            <TableCell>Percentage Confirmed</TableCell>
            <TableCell>Percentage Overall</TableCell>
            <TableCell>Percentage Reached</TableCell>
            <TableCell>Cumulative</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} sx={index % 2 === 0 ? { bgcolor: '#f5f5f5' } : {}}>
              <TableCell>{row.week}</TableCell>
              <TableCell>{row.year}</TableCell>
              <TableCell>{row.month}</TableCell>
              <TableCell>{row.day}</TableCell>
              <TableCell>{row.percentage_confirmed}%</TableCell>
              <TableCell>{row.percentage_overall}%</TableCell>
              <TableCell>{row.percentage_reached}%</TableCell>
              <TableCell>{row.cumulative}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ForecastingList;
