import React, { useState } from 'react';
import { TextField, Button, Grid, Paper } from '@material-ui/core';

import LoginPageVector from '../../assets/img/data_img.jpg';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitEnabled, setSubmitEnabled] = useState(false);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setSubmitEnabled(event.target.value !== '' && password !== '');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setSubmitEnabled(username !== '' && event.target.value !== '');
  };

  const handleSubmit = () => {
    // Perform your API call here using axios
    // axios
    //   .post('/api/login', { username, password })
    //   .then((response) => {
    //     // Handle successful login
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     // Handle login error
    //     console.error(error);
    //   });
  };

  return (
    
    <Grid container style={{ height: '100vh' }}>
        <Grid item xs={12} style={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }}><h1>PUBLIC HEALTH DASHBOARD</h1></Grid>
      {/* Left Side Column */}
      <Grid item xs={6} style={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      
        {/* Display the vector art, charts, and statistics image */}
        <img src={LoginPageVector} alt="Vector Art" style={{ maxHeight: '100%', maxWidth: '100%' }} />
      </Grid>

      {/* Right Side Column */}
      <Grid item xs={6} style={{ backgroundColor: '#8e44ad',display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper elevation={3} style={{ padding: '2rem', width: '80%' }}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                label="Username"
                value={username}
                onChange={handleUsernameChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!isSubmitEnabled}
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
