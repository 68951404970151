import React from 'react';
import { Typography, s } from '@mui/material';
import SankeyChart from '../chartTypes/SankeyChart';
import SubHeading from './SubHeading'

function ReachSankeyChart() {
 
  return (
      <div style={{ paddingTop: "30%" }}>
     
            <SubHeading text="Vaccine wise missed count" />
            <Typography variant="h6" component="h2">

            </Typography>
            <SankeyChart />
        
      </div>
  )
}

export default ReachSankeyChart
