import React, { useState, useEffect } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.png'

const Sidebar = () => {
  const [collapseID, setCollapseID] = useState("");
  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setSidebarHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleCollapse = (collapseID) => () => {
    setCollapseID(collapseID !== collapseID ? collapseID : "");
  };

  return (
    <div style={{ display: 'flex', height: '100%', overflow: 'scroll initial' }}>
      <CDBSidebar style={{ height: sidebarHeight,borderRight:"solid 1px #dddddd" }} textColor="#000" backgroundColor="#f8f9fa">
        <CDBSidebarHeader  prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
            <img src={logo} />

          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent  className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink  exact to="/" activeClassName="activeClicked">
              <CDBSidebarMenuItem style={{color:"#000"}} icon="syringe">Zero Dose/Dropout</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/trend" activeClassName="activeClicked">
              <CDBSidebarMenuItem style={{color:"#000"}} icon="chart-bar">Trend Analysis</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/literacy" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">Literacy</CDBSidebarMenuItem>
            </NavLink>
            
            <NavLink exact to="/hesitant" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">Hesitant Analysis</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/forcasting" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">Forcasting</CDBSidebarMenuItem>
            </NavLink>

          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            style={{
              padding: '20px 5px',
            }}
          >
         3 Analytics
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
