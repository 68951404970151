import React from 'react';

const SubHeading = ({ text }) => {
  const titleStyle = {
    textAlign: 'center',
    backgroundColor: '#f15757',
    color: 'white',
    width:'100%',
    height:'25px',
    fontWeight:'400'
  };

  return <h6 style={titleStyle}>{text}</h6>;
};

export default SubHeading;
