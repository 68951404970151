import React, { useState } from 'react';
import { Chart } from 'react-google-charts';

const SankeyChart = () => {
  const [selectedNode, setSelectedNode] = useState(null);

  const handleNodeClick = (e, chart) => {
    const selection = chart.getSelection();
    if (selection.length === 0) {
      setSelectedNode(null);
      return;
    }

    const [node] = selection;
    setSelectedNode(chart.getDataTable().getValue(node.row, 0));
  };

  const data = [
    ['From', 'To', 'Weight'],
    ['Total Count', 'MR1', 10],
    ['Total Count', 'MR2', 5],
  ];

  const options = {
    sankey: {
      node: {
        interactivity: true,
        label: {
          fontName: 'Arial',
          fontSize: 12,
          color: '#555',
          italic: false,
          bold: true,
        },
        width: 190, // Adjust the width of the nodes
      },
      link: {
        colorMode: 'gradient',
        colors: ['#E1E6F1', '#AAB7C9', '#5B7391'],
        width: 20, // Adjust the width of the links
      },
    },
  };

  return (
    <div >
      <Chart
        width={'600px'}
        height={'70px'}
        chartType="Sankey"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
        chartEvents={[
          {
            eventName: 'select',
            callback: handleNodeClick,
          },
        ]}
      />
      {selectedNode && <p>Selected Node: {selectedNode}</p>}
    </div>
  );
};

export default SankeyChart;
