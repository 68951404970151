import React from 'react';

const MainHeading = ({ text }) => {
  const titleStyle = {
    textAlign: 'center',
    backgroundColor: '#fff',
    color: '#000',
    width:'100%',
   
  };

  return <h2 style={titleStyle}>{text}</h2>;
};

export default MainHeading;
