import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import Sidebar from './layouts/sidebar/Sidebar';
import Login from './scenes/Athentication/Login';
import ChangePassword from './scenes/Athentication/ChangePassword';
import UserProfile from './scenes/Athentication/UserProfile';
import Dashboard from './scenes/Dashboard';
import Trend from './scenes/Trend';
import Forcasting from './scenes/Forcasting';
import Literacy from './scenes/Literacy';
import Hesitant from './scenes/Hesitant';



const App = () => {
  const location = useLocation();
  const isLogin = location.pathname.startsWith('/login');

  return (
    <div className="containerDiv">
      {!isLogin  && <Sidebar />}
      <div className="content">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/trend" element={<Trend />} />
          <Route path="/forcasting" element={<Forcasting />} />
          <Route path="/literacy" element={<Literacy />} />
          <Route path="/hesitant" element={<Hesitant />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/change-password" element={<ChangePassword />} />
          
        </Routes>
      </div>
    </div>
  );
}
function Router() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default Router;
