import React from 'react';

const SubHeading = ({ text }) => {
  const titleStyle = {
    textAlign: 'center',
    backgroundColor: '#f15757',
    color: 'white',
    width:'100%',
    height:'25px'
  };

  return <h5 style={titleStyle}>{text}</h5>;
};

export default SubHeading;
