import React, { useState } from 'react';
import {
  Button,
  Typography,
  Container,
  makeStyles,
  Modal,
  Paper,
  Grid,
  TextField,
  IconButton,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import ChangePassword from './ChangePassword';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto', // Align the button to the right side
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    outline: 'none',
    padding: theme.spacing(2),
    border: '2px solid #000',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  editableField: {
    display: 'flex',
    alignItems: 'center',
  },
  editIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const UserProfile = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [userProfile, setUserProfile] = useState({
    name: 'John Doe',
    role: 'Admin',
    email: 'john.doe@example.com',
    designation: 'Software Engineer',
    department: 'Engineering',
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFieldChange = (field, value) => {
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [field]: value,
    }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.container}>
        {/* User Profile Information */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5">{userProfile.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.editableField}>
              <Typography>Role:</Typography>
              <TextField
                value={userProfile.role}
                onChange={(e) => handleFieldChange('role', e.target.value)}
              />
              <IconButton className={classes.editIcon}>
                <EditIcon />
              </IconButton>
            </div>
            <div className={classes.editableField}>
              <Typography>Email:</Typography>
              <TextField
                value={userProfile.email}
                onChange={(e) => handleFieldChange('email', e.target.value)}
              />
              <IconButton className={classes.editIcon}>
                <EditIcon />
              </IconButton>
            </div>
            {/* Add more editable fields in a similar manner */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* Add user profile image here */}
            <img
              src="/path/to/user/profile/image.png"
              alt="User Profile"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Grid>
        </Grid>

        {/* Change Password Button */}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
        >
          Change Password
        </Button>

        {/* Modal for ChangePassword component */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          className={classes.modalContainer}
        >
          <Paper className={classes.modalContent}>
            <ChangePassword handleCloseModal={handleCloseModal} />
          </Paper>
        </Modal>
      </div>
    </Container>
  );
};

export default UserProfile;
