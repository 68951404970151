import React from 'react';
import Barchart from '../chartTypes/BarChart';
import SubHeading from './SubHeading';

const Moniter = () => {

  const vaccinatedYear = ['2022 December', '2023 January', '2023 February']
  const vaccinatedCountByYear = [141, 282, 162]

  return (
    <>
      <SubHeading text="  Day wise vaccinated count" />
      <Barchart xAxisData={vaccinatedYear} yAxisData={vaccinatedCountByYear} />
    </>
  );
};

export default Moniter;
