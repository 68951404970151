import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, CardContent, Card, Grid, Button } from '@mui/material';
import BarChart from './chartTypes/BarChart';
import Header from '../layouts/Header';
import BarChartHorizontal from './chartTypes/BarChartHorizontal';
import MultiDonutChart from './chartTypes/MultiDonutChart';
import BubbleChart from './chartTypes/BubbleChart';
import NetworkChart from './chartTypes/NetworkChart';
import SubHeading from './components/SubHeading';
import MissedVaccinationReasons from './components/MissedVaccinationReasons';
import EnquiryReport from './components/EnquiryReport';
import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, } from '@fortawesome/free-solid-svg-icons';
import Loader from './components/Loader';
import { ApiUrl } from './ApiConstant';
import { Service } from './AppService';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: "#e9ecef"
  },
  card: {
    minWidth: 200, // Updated width value (adjust as needed)
    margin: '1rem',
    textAlign: 'center',
  },
  appBar: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
});

const Hesitant = () => {
  const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [missedVaccineData, setMissedVaccineData] = useState(null);
  const [missedVaccineCount, setMissedVaccineCount] = useState(null);
  const [missedDistrict, setMissedDistrict] = useState(null);
  const [missedVaccinedByDistrict, setMissedVaccinedByDistrict] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [notVaccinatedCount, setNotVaccinatedCount] = useState(null);
  const [hesitantList, setHesitantList] = useState(null);


  const vaccineNames = ['OPV', 'MEASLES-1&MR-1', 'MEASLES-2&MR-2', 'DPT']
  const MissedVaccineCountByVaccine = [10, 6, 6, 6]

  //Hesistant Vaccine count
  const getVaccineMissedCount = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.HESITANT_VACCINE_WISE_MISSED_COUNT);
      if (response) {
        const vaccines = response.map(item => item.vaccine_name);
        const count = response.map(item => item.missed_count);
        setMissedVaccineData(vaccines);
        setMissedVaccineCount(count);


      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getVaccineMissedCount();
    }
  }, [hasLoaded]);


  //Hesistant Vaccine count by district
  const getVaccineMissedCountBydistrict = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.HESITANT_MISSED_VACCINE_COUNT_DISTRICT);
      if (response) {
        const district = response.map(item => item.district_name);
        const count = response.map(item => item.missed_count);
        setMissedDistrict(district);
        setMissedVaccinedByDistrict(count);


      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getVaccineMissedCountBydistrict();
    }
  }, [hasLoaded]);

  //Not Vaccinted reason
  const getNotVaccinatedReason = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.HESITANT_MISSED_VACCINE_REASON);
      if (response !== undefined) {
        const remark = response.remarks.map(remark => remark.remark_not_vaccinating);
        const count = response.remarks.map(remark => remark.unvaccinated_count);
        setRemarks(remark);
        setNotVaccinatedCount(count);


      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getNotVaccinatedReason();
    }
  }, [hasLoaded]);

  //HESITANT LIST API
  const getHesitantList = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.HESITANT_LIST);
      if (response !== undefined) {
        setHesitantList(response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getHesitantList();
    }
  }, [hasLoaded]);

  //Export to PPT
  const hesitantHorizontalBarChartRef = useRef(null);
  const hesitantMultiDonutChartRef = useRef(null);
  const hesitantBubbleChartRef = useRef(null);
  const hesitantBarChartRef = useRef(null);
  const hesitantMissedVaccinationReasonsRef = useRef(null);

  const handleConvertToImage = () => {
    setLoading(true);

    const promises = [];
    let hesitantHorizontalBarChartImage = null;
    let hesitantMultiDonutChartImage = null;
    let hesitantBubbleChartImage = null;
    let hesitantBarChartImage = null;
    let hesitantMissedVaccinationReasonsImage = null;



    promises.push(
      html2canvas(hesitantHorizontalBarChartRef.current).then((canvas1) => {
        hesitantHorizontalBarChartImage = canvas1.toDataURL('image/png');
      })
    );

    promises.push(
      html2canvas(hesitantMultiDonutChartRef.current).then((canvas2) => {
        hesitantMultiDonutChartImage = canvas2.toDataURL('image/png');
      })
    );
    promises.push(
      html2canvas(hesitantBubbleChartRef.current).then((canvas3) => {
        hesitantBubbleChartImage = canvas3.toDataURL('image/png');
      })
    );
    promises.push(
      html2canvas(hesitantBarChartRef.current).then((canvas4) => {
        hesitantBarChartImage = canvas4.toDataURL('image/png');
      })
    );
    promises.push(
      html2canvas(hesitantMissedVaccinationReasonsRef.current).then((canvas5) => {
        hesitantMissedVaccinationReasonsImage = canvas5.toDataURL('image/png');
      })
    );

    Promise.all(promises).then(() => {
      downloadPptx(hesitantHorizontalBarChartImage, hesitantMultiDonutChartImage, hesitantBubbleChartImage, hesitantBarChartImage, hesitantMissedVaccinationReasonsImage);
    });
  };

  const [slideTitle, setSlideTitle] = useState(`HESISTANT`);
  const [slideContent, setSlideContent] = useState("");
  const [loading, setLoading] = useState(false);
  const downloadPptx = (hesitantHorizontalBarChartImage, hesitantMultiDonutChartImage, hesitantBubbleChartImage, hesitantBarChartImage, hesitantMissedVaccinationReasonsImage) => {
    const pptxy = new PptxGenJS();

    const slide0 = pptxy.addNewSlide({ sectionTitle: slideTitle });
    slide0.addText(slideTitle, { x: 3.5, y: 2.5, fontSize: 24 });

    if (hesitantHorizontalBarChartImage) {
      const slide1 = pptxy.addNewSlide({ sectionTitle: "slide 1" });
      slide1.addImage({ x: 2, y: 1, w: 5.5, h: 2.5, data: hesitantHorizontalBarChartImage });
    }

    if (hesitantMultiDonutChartImage) {
      const slide2 = pptxy.addNewSlide({ sectionTitle: "Slide 2" });
      slide2.addImage({ x: 2, y: 1, w: 5.5, h: 3, data: hesitantMultiDonutChartImage });
    }

    if (hesitantBubbleChartImage) {
      const slide3 = pptxy.addNewSlide({ sectionTitle: "Slide 3" });
      slide3.addImage({ x: 2, y: 1, w: 6.5, h: 3, data: hesitantBubbleChartImage });
    }
    if (hesitantBarChartImage) {
      const slide4 = pptxy.addNewSlide({ sectionTitle: "Slide 3" });
      slide4.addImage({ x: 2, y: 1, w: 6.5, h: 3, data: hesitantBarChartImage });
    }
    if (hesitantMissedVaccinationReasonsImage) {
      const slide5 = pptxy.addNewSlide({ sectionTitle: "Slide 4" });
      slide5.addImage({ x: 2, y: 1, w: 6.5, h: 3, data: hesitantMissedVaccinationReasonsImage });
    }


    pptxy.writeFile({ fileName: `identify.pptx` }).then(() => {
      setLoading(false);
    });
  };

  return (
    <div style={{ height: "auto" }} className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
          <Header enabled={true} edit={false} chartType={true} filterIcon={true} title={"Hesitant Analysis"} />
          <Button
            onClick={handleConvertToImage}
            id="filterButton"
            color="primary"
            style={{
              border: 'none',
              background: 'transparent',
              color: '#fff',
              marginLeft: '0%'
            }}
          >
            <FontAwesomeIcon size="lg" icon={faFileArrowDown} />
          </Button>
          {loading && (
            <div className="loader">
              <Loader text="Generating PPT file" />
            </div>
          )}
        </Toolbar>
      </AppBar>


      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent style={{ height: "400px" }}>
              <div ref={hesitantMultiDonutChartRef}> <SubHeading text=" Missed vaccine count by district and vaccine " />

                <MultiDonutChart />
              </div>

            </CardContent>
          </Card>
        </Grid>


      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardContent style={{ height: "460px" }}>
              <div ref={hesitantBarChartRef}>
                <SubHeading text="Missed vaccine count by district" />

                {hasLoaded ? (
                  missedDistrict && missedVaccinedByDistrict && (
                    <BarChart xAxisData={missedDistrict} yAxisData={missedVaccinedByDistrict} />
                  )
                ) : (
                  <Loader text="Loading..." />
                )}
              </div>


            </CardContent>
          </Card>

        </Grid>

        {/* <Grid item xs={6}>
          <Card className={classes.card}>
            <CardContent style={{ height: "460px" }}>
              <div ref={hesitantBubbleChartRef}>
                <SubHeading text=" Missded vaccinated count by district name and vaccine" />
                <BubbleChart />
              </div>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardContent style={{ height: "400px" }}>
              <div ref={hesitantHorizontalBarChartRef}> <SubHeading text=" Missed vaccine count by vaccine " />
                {hasLoaded ? (
                  missedVaccineData && missedVaccineCount && (
                    <BarChartHorizontal xAxisData={missedVaccineData} yAxisData={missedVaccineCount} />
                  )
                ) : (
                  <Loader text="Loading..." />
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardContent style={{ height: "490px" }}>
              <div ref={hesitantMissedVaccinationReasonsRef}>
                <SubHeading text="Reasons for missing vaccination" />


                {hasLoaded ? (
                  remarks && notVaccinatedCount && (
                    <MissedVaccinationReasons remarks={remarks} NotVaccinatedCount={notVaccinatedCount} />
                  )
                ) : (
                  <Loader text="Loading..." />
                )}
              </div>


            </CardContent>
          </Card>

        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardContent style={{ height: "490px" }}>
              <SubHeading text="Missed vaccine count by  vaccine " />
              <BarChart xAxisData={vaccineNames} yAxisData={MissedVaccineCountByVaccine} />
              {hasLoaded ? (
                missedVaccineData && missedVaccineCount && (

                  <BarChart xAxisData={missedVaccineData} yAxisData={missedVaccineCount} />
                )
              ) : (
                <Loader text="Loading..." />
              )}
            </CardContent>
          </Card>

        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent >
              <SubHeading text="Hesitant enquiry Report" />
           
              {hasLoaded ? (
                  hesitantList &&  (
                    <EnquiryReport hesitantList={hesitantList} />
                  )
                ) : (
                  <Loader text="Loading..." />
                )}
            </CardContent>
          </Card>

        </Grid>

      </Grid>
    </div>
  );
};

export default Hesitant;
