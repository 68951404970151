import React from 'react';
import { Card } from '@mui/material';
import Chart from 'react-google-charts';
import { MapContainer as Map, CircleMarker, TileLayer, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const GeoChartMap = () => {
  const chartData = [
    ['State', 'Districts'],
    ['Andhra Pradesh', 13],
    ['Arunachal Pradesh', 20],
    ['Assam', 33],
    // Add more states and districts here
  ];
  const data = {
    city: [
    
    
      { "name": "Bhopal", "coordinates": [77.412613, 23.259933], "population": 24998000 },
      { "name": "Indore", "coordinates": [75.857727, 	22.719568], "population": 17712000 },
      { "name": "Sagar", "coordinates": [78.7378, 23.8388], "population": 14667000 },
    
    ],
    minLat: -6.1751,
    maxLat: 55.7558,
    minLong: 37.6173,
    maxLong: 139.6917
  }
  let centerLat = (data.minLat + data.maxLat) / 2;
  let distanceLat = data.maxLat - data.minLat;
  let bufferLat = distanceLat * 0.05;
  let centerLong = (data.minLong + data.maxLong) / 2;
  let distanceLong = data.maxLong - data.minLong;
  let bufferLong = distanceLong * 0.15;
  return (
    <Card>
      {/* <Chart
        width={'100%'}
        height={'400px'}
        chartType="GeoChart"
        data={chartData}
        options={{
          region: 'IN',
          resolution: 'provinces',
          colorAxis: { colors: ['#C8EEFF', '#0077E6'] },
          backgroundColor: '#f5f5f5',
          datalessRegionColor: '#cccccc',
          defaultColor: '#f5f5f5',
        }}
        rootProps={{ 'data-testid': '1' }}
      /> */}

      <Map
        style={{ height: "480px", width: "100%" }}
        zoom={4}
        center={['20.5937', '78.9629']}
        bounds={[
          [data.minLat - bufferLat, data.minLong - bufferLong],
          [data.maxLat + bufferLat, data.maxLong + bufferLong]
        ]}
      >
        <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {data.city.map((city, k) => {
          return (
            <CircleMarker
              key={k}
              center={[city["coordinates"][1], city["coordinates"][0]]}
              radius={20 * Math.log(city["population"] / 10000000)}
              fillOpacity={0.5}
              stroke={false}
            >
              <Tooltip direction="right" offset={[-8, -2]} opacity={1}>
                <span>{city["name"] + ": " + "Population" + " " + city["population"]}</span>
              </Tooltip>
            </CircleMarker>)
        })
        }
      </Map>
    </Card>
  );
};

export default GeoChartMap;
