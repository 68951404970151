import React from 'react';
import DonutChart from '../chartTypes/DonutChart';
import SubHeading from './SubHeading';

const Moniter = () => {

  const vaccineNames = ['MR', 'OPV', 'DPT', 'MEASLES OUTBREAK']
  const vaccinePercentage = [40, 281, 256, 8]

  return (
    <>
      <SubHeading text=" Vaccine wise vaccinated count" />

      <DonutChart labelsData={vaccineNames} seriesData={vaccinePercentage} />
    </>
  );
};

export default Moniter;
