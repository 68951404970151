import React, { useState } from 'react';
import { Row, Button, Offcanvas, OffcanvasBody, OffcanvasHeader, Card, CardBody, CardTitle, CardText, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFilter, } from '@fortawesome/free-solid-svg-icons';
import { Tabs, Tab, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import ChartDataFilter from '../scenes/components/ChartDataFilter';
import SubHeading from '../scenes/components/SubHeading'


const tabMenus = [
  { label: 'Select Chart Type', value: 'chart' },
  { label: 'Filters', value: 'filter' },
];

const sourcefilters = [
  { label: 'All', value: 'all' },
  { label: 'LABCORP ', value: 'LABCORP' },
  { label: 'WHO', value: 'WHO' },
  { label: 'HC', value: 'HC' },
];
const drugfilters = [
  { label: 'All', value: 'all' },
  { label: 'Acetaminophen', value: 'Acetaminophen' },
  { label: 'Codeine', value: 'Codeine' },
  { label: 'Naproxen', value: 'Naproxen' },
  { label: 'Celecoxib', value: 'Celecoxib' },
  { label: 'Diclofenac', value: 'Diclofenac' },
  { label: 'Diclofenac', value: 'Diclofenac' },
];
const seriousnessfilters = [
  { label: 'All', value: 'all' },
  { label: 'Non Serious', value: 'NonSerious' },
  { label: 'Serious', value: 'Serious' },

];
const labelExpected = [
  { label: 'All', value: 'all' },
  { label: 'YES', value: 'YES' },
  { label: 'NO', value: 'NO' },
]

const smqfilters = [
  { label: 'All', value: 'all' },
  { label: 'Dementia (SMQ) [Broad]', value: 'Dementia (SMQ) [Broad]' },
  { label: 'Depression (excl suicide and self injury) (SMQ) [Broad]', value: 'Depression (excl suicide and self injury) (SMQ) [Broad]' },
  { label: 'Extrapyramidal syndrome (SMQ) [Broad]', value: 'Extrapyramidal syndrome (SMQ) [Broad]' },
  { label: 'GuillainBarre syndrome (SMQ) [Broad]', value: 'GuillainBarre syndrome (SMQ) [Broad]' },
  { label: 'Hearing and vestibular disorders (SMQ) [Broad]', value: 'Hearing and vestibular disorders (SMQ) [Broad]' },
  { label: 'Hearing and vestibular disorders (SMQ) [Narrow]', value: 'Hearing and vestibular disorders (SMQ) [Narrow]' },
];
const OffcanvasModal = ({ title, filterIcon, chartType, enabled, edit, component, dashboardId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const [tabMenu, setTabMenu] = useState(tabMenus[0].value);
  const [sourcefilter, setSourceFilter] = useState(sourcefilters[0].value);
  const [drugfilter, setDrugFilter] = useState(drugfilters[0].value);
  const [seriousnessfilter, setSeriousnessFilter] = useState(seriousnessfilters[0].value);
  const [smqfilter, setSmqFilter] = useState(smqfilters[0].value);


  const handleTabMenuChange = (event, newValue) => {
    setTabMenu(newValue);
  };

  const handleSourceFilterChange = (event) => {
    setSourceFilter(event.target.value);
  };

  const handleDrugFilterChange = (event) => {
    setDrugFilter(event.target.value);
  };
  const handleSmqilterChange = (event) => {
    setSmqFilter(event.target.value);
  };
  const handleSeriousnessFilterChange = (event) => {
    setSeriousnessFilter(event.target.value);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div><h5 style={{ marginRight: '0px' }}>{title}</h5></div>
        {filterIcon === true ? <Tooltip placement="top" target="filterButton">Filter</Tooltip>:""}
        {filterIcon === true ? <Button id="filterButton" color="primary" onClick={toggle} style={{ border: 'none', background: 'transparent', color: '#fff' }}>
          <FontAwesomeIcon icon={faFilter} size="md" />
        </Button> : ""}
      </div>

      {isOpen && <div className="offcanvas-overlay" onClick={toggle} />}
      <Offcanvas isOpen={isOpen} toggle={toggle} className="offcanvas" style={{ animation: 'bounce-in-right 1s ease normal', padding: "5px", background: "#fff", position: 'fixed', top: '80px', left: 'auto', right: '0', width: '400px', height: '99vh', borderLeft: '3px solid red', borderBottom: '3px solid red', borderTop: '1px solid red', borderRight: '1px solid red', borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px", borderRadius: '10px' }}>
        <OffcanvasHeader style={{ position: 'relative', top: -10 }}>
          <Button color="link" className="offcanvas-close" onClick={toggle} style={{ float: 'right', position: 'absolute', left: 0, border: 'none', background: 'transparent', padding: '5px' }}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </Button>
        </OffcanvasHeader>
        <OffcanvasBody className="bg-white">
          <Card>
            <CardBody>
              <>
                <SubHeading text="Filters" />
                <ChartDataFilter />
              </>
            </CardBody>
          </Card>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default OffcanvasModal;
