import React, { useState } from "react";
import * as d3 from "d3";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const data = [
  { category: "Morena", value: 30, subcategories: ["DPT-B1  (3)", "OPV-0  (3)", "MEASLES-1/MR-1 (2) "] },
  { category: "Barwani", value: 20, subcategories:["DPT-B1  (3)", "OPV-0  (1)", "MEASLES-1/MR-1 (2) ","MEASLES-2/MR-2 (1)"] },
  { category: "Shahdol", value: 10, subcategories: ["OPV-0  (0)", "MEASLES-2/MR-2 (2) "] },
];

const PieChart = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  const pie = d3.pie().value(d => d.value);
  const colors = d3.schemeCategory10;

  const handleClick = category => {
    setActiveCategory(category);
  };

  const renderSubcategoryPieChart = () => {
    if (activeCategory) {
      const subcategoryData = data.find(d => d.category === activeCategory).subcategories.map((subcat, index) => ({
        category: subcat,
        value: 1,
      }));
      const subcategoryPie = d3.pie().value(d => d.value);
      const arc = d3.arc().innerRadius(70).outerRadius(100);
      const subcategoryColors = d3.schemeCategory10;

      return (
        <svg width={300} height={300}>
          <g transform={`translate(150, 150)`}>
            {subcategoryPie(subcategoryData).map((slice, index) => (
              <g key={index}>
                <path
                  d={arc(slice)}
                  fill={subcategoryColors[index % subcategoryColors.length]}
                />
                <text
                  transform={`translate(${arc.centroid(slice)})`}
                  dy="0.15em"
                  style={{ textAnchor: "middle" }}
                >
                  {slice.data.category}
                </text>
              </g>
            ))}
          </g>
        </svg>
      );
    }
    return null;
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <svg width={400} height={400}>
        <g transform={`translate(200, 200)`}>
          {pie(data).map((slice, index) => (
            <g key={index} onClick={() => handleClick(slice.data.category)} style={{ cursor: "pointer" }}>
              <path
                d={d3.arc().innerRadius(70).outerRadius(100)(slice)}
                fill={colors[index % colors.length]}
              />
              <text
                transform={`translate(${d3.arc().innerRadius(80).outerRadius(120).centroid(slice)})`}
                dy="0.35em"
                style={{ textAnchor: "middle" }}
              >
                {slice.data.category}
              </text>
            </g>
          ))}
        </g>
      </svg>
      {activeCategory && (
        <Box ml={4}>
          <Typography variant="h6" gutterBottom>
            {activeCategory}
          </Typography>
          {renderSubcategoryPieChart()}
          {/* <List>
            {data.find(d => d.category === activeCategory).subcategories.map((subcat, index) => (
              <ListItem key={index}>
                <ListItemText primary={subcat} />
              </ListItem>
            ))}
          </List> */}
        </Box>
      )}
    </Box>
  );
};

export default PieChart;
