import axios from "axios";
import toastr from "toastr";

class service {

  objectMap = (obj, fn) =>
    Object.fromEntries(
      Object.entries(obj).map(
        ([k, v], i) => [k, fn(v, k, i)]
      )
    )
  Appservice = async (method, url) => {

    let response;
    return axios({
      method: method,
      url: url,
   
    
    }).then((res) => {
      response = res.data;
      if (!response) response = true;
      return response;
    })
      .catch((e) => {
        let errText = 'Something went wrong...';

        if (e?.response?.status === 500) {
          toastr.error('Server error', 'Public Health Dashboard');
          return false;
        }
        if (!e?.response?.headers) {
          toastr.error(errText, 'Public Health Dashboard');
          return false;
        }
        if (typeof e.response.data === 'object') {
          errText = Object.entries(e.response.data).map(([k, v]) => `${k} : ${v.toString()}`)
        }
        if (e.response && e.response.data && e.response.data.message) errText = e.response.data.message;
        if (e.response && e.response.data && e.response.data.msg) errText = e.response.data.msg;

        toastr.error(errText, 'Public Health Dashboard');
        return false;
      })
      .finally(() => {
        return response;
      });
  }


}

export const Service = new service();