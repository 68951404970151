import React from 'react';
import Barchart from '../chartTypes/BarChart';
import SubHeading from './SubHeading'

function ReachBarChart() {

  const remarks = ['Nothing Specific', 'parents were out of station', 'parents was not aware', 'Vaccination center is closed ', `Child's health issue did not permit`]
  const remarksCount = [45, 18, 9, 9, 3]
  return (
    <div style={{ paddingTop: "20%" }}>
      <SubHeading text=" Remarks for not vaccinating" />
      <Barchart xAxisData={remarks} yAxisData={remarksCount} />
    </div>
  )
}

export default ReachBarChart
