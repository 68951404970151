import React, { useState, useEffect } from "react";
import './Loader.css';

const Loader = ({ text }) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent(prevPercent => prevPercent + 1);
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loader-overlay">
      <div className="spinner">
   
        <div className="loading-text">{text}</div>
        <div className="percent">{percent}%</div>
      </div>
    </div>
  );
};

export default Loader;
