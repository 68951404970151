import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  label: {
    fontWeight: 'bold',
  },
}));
const Filter = () => {
  const classes = useStyles();

  const selectOptions = [
    {
      name: 'Districts',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Agarmalwa', value: 'user1' },
        { label: 'Alirajpur', value: 'user2' },
        { label: 'Anuppur', value: 'user3' },
        { label: 'Ashoknagar', value: 'user3' },
        { label: 'Balaghat', value: 'user3' },
      ],
    },
    {
      name: 'Villages',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Aadarsh paie', value: 'pending' },
        { label: 'Aadiwashi dera', value: 'completed' },
        { label: 'Abhana', value: 'cancelled' },
        { label: 'Abud', value: 'cancelled' },
        { label: 'Accharohi', value: 'cancelled' },
      ],
    },
    {
      name: 'Vaccines',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Albendazol', value: 'source1' },
        { label: 'BCG', value: 'source2' },
        { label: 'DPT-1', value: 'source3' },
        { label: 'DPT-2', value: 'source3' },
        { label: 'DPT-3', value: 'source3' },
      ],
    },
    {
      name: 'Health block',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Agar', value: 'source1' },
        { label: 'Alirajpur', value: 'source2' },
        { label: 'Alot', value: 'source3' },
        { label: 'Amarpatan', value: 'source3' },
        { label: 'Amarpur', value: 'source3' },
      ],
    },
  ];

  return (
    <div className={classes.container}>
      {selectOptions.map((selectOption, index) => (
        <FormControl key={index}>
          <InputLabel className={classes.label} htmlFor={selectOption.name}>
            {selectOption.name}
          </InputLabel>
          <Select id={selectOption.name}>
            {selectOption.options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
    </div>
  );
};

export default Filter;
