import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import female from '../../assets/img/female.png';
import male from '../../assets/img/male.png';

const sampleData = [
  { district: 'Barwari', female: 7, male: 12, femalePercentage: 35, malePercentage: 60, children: 1, childrenPercentage: 5 },
  { district: 'Damoh', female: 0, male: 1, femalePercentage: 0, malePercentage: 100, children: 0, childrenPercentage: 0 },
  { district: 'Datia', female: 8, male: 13, femalePercentage: 38, malePercentage: 62, children: 0, childrenPercentage: 0 },
  { district: 'Morena', female: 0, male: 8, femalePercentage: 0, malePercentage: 100, children: 0, childrenPercentage: 0 },
  { district: 'Raisen', female: 2, male: 7, femalePercentage: 20, malePercentage: 70, children: 1, childrenPercentage: 10 },
  // Add more districts and data as needed
];

const GenderBox = ({ label, percentage, imageSrc, color }) => {
  const fillHeight = percentage === 0 ? 0 : percentage;

  const boxStyle = {
    width: '100px',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    margin: '10px',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    objectFit: 'cover',
    opacity: '0.5',
  };

  const overlayStyle = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    height: `${fillHeight}%`,
    backgroundColor: color,
    opacity: 0.6,
    zIndex: 1,
  };

  const labelStyle = {
    position: 'absolute',
    bottom: '15px',
    left: '0',
    right: '0',
    width: '100%',
    padding: '3px',
    textAlign: 'center',
    fontWeight: '400'
  };

  const percentageStyle = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    textAlign: 'center',
    fontWeight: '400'
  };

  return (
    <div className="gender-box" style={boxStyle}>
      <img src={imageSrc} alt={label} style={imageStyle} />
      <div className="color-overlay" style={overlayStyle}></div>
      <span className="gender-label" style={labelStyle}>
        {label}
      </span>
      <span className="gender-percentage" style={percentageStyle}>
        {percentage.toFixed(2)}%
      </span>
    </div>
  );
};

const GenderChart = () => {
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [totalMalePercentage, setTotalMalePercentage] = useState(0);
  const [totalFemalePercentage, setTotalFemalePercentage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    // Calculate the total male and female percentages based on the total number of all districts
    let totalMaleCount = 0;
    let totalFemaleCount = 0;
    let totalCount = 0;

    sampleData.forEach((item) => {
      totalMaleCount += item.male;
      totalFemaleCount += item.female;
      totalCount += item.male + item.female;
    });

    const totalMalePercentage = (totalMaleCount / totalCount) * 100;
    const totalFemalePercentage = (totalFemaleCount / totalCount) * 100;
    setTotalMalePercentage(totalMalePercentage);
    setTotalFemalePercentage(totalFemalePercentage);
  }, []);

  const onClickRow = (districtData) => {
    setSelectedDistrict(districtData);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const paginatedData = sampleData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(sampleData.length / itemsPerPage);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <GenderBox
          label="Male"
          percentage={selectedDistrict ? selectedDistrict.malePercentage : totalMalePercentage}
          imageSrc={male}
          color="#0d6efd"
        />
        <GenderBox
          label="Female"
          percentage={selectedDistrict ? selectedDistrict.femalePercentage : totalFemalePercentage}
          imageSrc={female}
          color="rgb(201, 0, 255)"
        />
      </div>
      <table style={{ borderCollapse: 'collapse', width: '100%', cursor: 'pointer' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', background: '#f0f0f0' }}>District</th>
            <th style={{ border: '1px solid black', background: '#f0f0f0' }}>Female Children</th>
            <th style={{ border: '1px solid black', background: '#f0f0f0' }}>Female Children %</th>
            <th style={{ border: '1px solid black', background: '#f0f0f0' }}>Male Children</th>
            <th style={{ border: '1px solid black', background: '#f0f0f0' }}>Male Children %</th>
            <th style={{ border: '1px solid black', background: '#f0f0f0' }}>Children</th>
            <th style={{ border: '1px solid black', background: '#f0f0f0' }}>Children %</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item, index) => (
            <tr key={index} onClick={() => onClickRow(item)} style={{ border: '1px solid black' }}>
              <td style={{ borderRight: '1px solid black', padding: '10px' }}>{item.district}</td>
              <td style={{ padding: '10px' }}>{item.female}</td>
              <td style={{ padding: '10px' }}>{item.femalePercentage}%</td>
              <td style={{ padding: '10px' }}>{item.male}</td>
              <td
                style={{ padding: '10px' }}>{item.malePercentage}%</td>
              <td style={{ padding: '10px' }}>{item.children}</td>
              <td style={{ padding: '10px' }}>{item.childrenPercentage}%</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </div>
  );
};

export default GenderChart;
