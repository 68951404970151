import React from 'react';
import BarChartHorizontal from '../chartTypes/BarChartHorizontal';
import SubHeading from './SubHeading';

const Moniter = () => {
  
  const district = ['chhatarpur', 'Tikamgarh', 'Morena', 'Shahdol', 'Mandla']
  const vaccinatedCountByDistrict = [74, 73, 50, 49, 29]

  return (
    <>
      <SubHeading text=" District  wise vaccinated count" />

      <BarChartHorizontal xAxisData={district} yAxisData={vaccinatedCountByDistrict} />
    </>
  );
};

export default Moniter;
