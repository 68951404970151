import React from 'react';
import Chart from 'react-apexcharts';

const HorizontalBarChart = ({xAxisData, yAxisData}) => {
 
  const chartOptions = {
    chart: {
      id: 'horizontal-bar-chart',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: xAxisData,
    },
  };

  const chartSeries = [
    {
      name: 'Series 1',
      data: yAxisData,
    },
  ];

  return (
    <Chart options={chartOptions} series={chartSeries} type="bar" height={300} />
  );
};

export default HorizontalBarChart;
