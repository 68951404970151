
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const ApiUrl = {
  //forcasting
  FORCASTING_AVERAGE_PERCENT_BY_YEAR: apiEndpoint + 'forecasting/average_percentage_by_year/',
  FORCASTING_LIST: apiEndpoint + 'forecasting/list/',
  //Trend
  TREND_WEEK_WISE_SUCCESS_COUNT: apiEndpoint + 'trend/weekwise-success-count/',
  TREND_VACCINE_WISE_SUCCESS_COUNT: apiEndpoint + 'trend/vaccine-wise-success-count/',
  TREND_WEEK_WISE_VACCINE_WISE_COUNT: apiEndpoint + 'trend/week-wise-vacinateunvaccinated-count/',
  //Hesistant
  HESITANT_VACCINE_WISE_MISSED_COUNT:     apiEndpoint + 'hesitant/vaccine-wise-missed-count/',
  HESITANT_MISSED_VACCINE_COUNT_DISTRICT: apiEndpoint + 'hesitant/missed-vaccine-count-by-district/',
  HESITANT_MISSED_VACCINE_REASON:         apiEndpoint + 'hesitant/miss-vaccine-and-reason/',
  HESITANT_VACCINE_DISTRICT_WISE_MISSED_COUNT:  apiEndpoint + 'hesitant/vaccine-and-district-wise-missed-count/',
  HESITANT_LIST:  apiEndpoint + 'hesitant/list',

  LITERACY_GET_ALL: apiEndpoint + 'literacy_records/',


}
export { ApiUrl }