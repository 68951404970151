import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Header from '../layouts/Header';
import { AppBar, Toolbar, CardContent, Card, Grid, Button } from '@mui/material';
import AreaChart from '../scenes/chartTypes/AreaChart';
import ForeCastingList from '../scenes/components/ForeCastingList';
import SubHeading from './components/SubHeading';
import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, } from '@fortawesome/free-solid-svg-icons';
import Loader from './components/Loader';
import { ApiUrl } from './ApiConstant';
import { Service } from './AppService';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: "#e9ecef"
  },
  card: {
    minWidth: 275,
    margin: '1rem',
    textAlign: 'center',
  },
  appBar: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
});

const Forecasting = () => {
  const classes = useStyles();
  const [averagePercentageByYear, setAveragePercentageByYear] = useState(null);
  const [percentageList, setPercentageList] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  //Forecast average % by year api
  const getAveragePercentageByYear = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.FORCASTING_AVERAGE_PERCENT_BY_YEAR);
      if (response) {
        setAveragePercentageByYear(response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getAveragePercentageByYear();
    }
  }, [hasLoaded]);
  //Forecast lisitng api
  const getPercentageList = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.FORCASTING_LIST);
      if (response) {
        setPercentageList(response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getPercentageList();
    }
  }, [hasLoaded]);

  const forcastingAreaChartRef = useRef(null);
  //const forcastingListRef = useRef(null);
  const handleConvertToImage = () => {
    setLoading(true);
    const promises = [];
    let forcastingAreaChartImage = null;
    let forcastingListChartImage = null;
    promises.push(
      html2canvas(forcastingAreaChartRef.current).then((canvas1) => {
        forcastingAreaChartImage = canvas1.toDataURL('image/png');
      })
    );

    Promise.all(promises).then(() => {
      downloadPptx(forcastingAreaChartImage, forcastingListChartImage);
    });
  };

  const slideTitle = `FORCASTING`
  const [loading, setLoading] = useState(false);
  const downloadPptx = (forcastingAreaChartImage, forcastingListChartImage) => {
    const pptxy = new PptxGenJS();

    const slide0 = pptxy.addNewSlide({ sectionTitle: slideTitle });
    slide0.addText(slideTitle, { x: 3.5, y: 2.5, fontSize: 24 });

    if (forcastingAreaChartImage) {
      const slide1 = pptxy.addNewSlide({ sectionTitle: "slide 1" });
      slide1.addImage({ x: 1, y: 1, w: 8.5, h: 3, data: forcastingAreaChartImage });
    }

    if (forcastingListChartImage) {
      const slide2 = pptxy.addNewSlide({ sectionTitle: "Slide 2" });
      slide2.addImage({ x: 1, y: 1, w: 8.5, h: 3, data: forcastingListChartImage });
    }



    pptxy.writeFile({ fileName: `forcasting.pptx` }).then(() => {
      setLoading(false);
    });
  };

  return (
    <div style={{ height: "auto" }} className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
          <Header enabled={true} edit={false} chartType={true} filterIcon={false} title={"Forecasting Analysis"} />
          <Button
            onClick={handleConvertToImage}
            id="filterButton"
            color="primary"
            style={{
              border: 'none',
              background: 'transparent',
              color: '#fff',
              marginLeft: '0%'
            }}
          >
            <FontAwesomeIcon size="lg" icon={faFileArrowDown} />
          </Button>
          {loading && (
            <div className="loader">
              <Loader text="Generating PPT file" />
            </div>
          )}
        </Toolbar>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={{ height: "auto" }} className={classes.card}>
            <CardContent style={{ padding: '5%' }}>

              <div ref={forcastingAreaChartRef}>
                <SubHeading text="Average of Percentage(overall) by Year" />
                {hasLoaded ? (
                  averagePercentageByYear && (

                    <AreaChart chartData={averagePercentageByYear} />

                  )
                ) : (
                  <Loader text="Loading..." />
                )}

              </div>
              <div >

                {hasLoaded ? (
                  percentageList && (

                    <ForeCastingList data={percentageList} />

                  )
                ) : (
                  <Loader text="Loading..." />
                )}

              </div>

            </CardContent>
          </Card>
        </Grid>

      </Grid>

    </div>
  );
};

export default Forecasting;
