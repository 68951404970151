import React from 'react';
import GeoChart from '../chartTypes/GeoChart';
import SubHeading from './SubHeading'

const IdentifyGeoChart = () => {
  //Data integration for the  Geo chart

  return (
    <div>
      <SubHeading text="Missed vaccine count by district" />
      <GeoChart />
    </div>
  );
};

export default IdentifyGeoChart;
