import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineBar = ({category, lineData, barData}) => {
  const options = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 2, 5],
      curve: 'smooth',
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
      line: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    xaxis: {
      type: 'category',
      categories:category,
    },
    series: [
      {
        name: 'Total number of non vaccination count',
        type: 'line',
        data: lineData,
      },
      {
        name: 'Success count',
        type: 'column',
        data: barData,
      },
    ],
  };

  return (
    <div>
      <ReactApexChart options={options} series={options.series} type="line" height={350} />
    </div>
  );
};

export default LineBar;
