import React from 'react';
import Chart from 'react-apexcharts';

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: 'line-chart',
          zoom: {
            enabled: true
          }
        },
        xaxis: {
          categories: [ '2022-12-01', '2022-12-05', '2022-12-10', '2022-12-15', '2022-12-20', '2022-12-25', '2022-12-30', 
          '2023-01-01', '2023-01-05', '2023-01-10', '2023-01-15', '2023-01-20', '2023-01-25', '2023-01-30', 
          '2023-02-01', '2023-02-05', '2023-02-10', '2023-02-15', '2023-02-20', '2023-02-25', '2023-02-28'  ]
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        }
      },
      series: [
        {
          name: 'Sales',
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 90, 85, 100]
        }
      ]
    };
  }

  render() {
    return (
      <div>
        <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
      </div>
    );
  }
}

export default LineChart;
