import React, { useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import SubHeading from './SubHeading'

const IdentifyHorizontalBarChart = () => {
  // Sample data for the stacked bar chart
  const chartOptions = {
    chart: {
      stacked: true,
    },
    xaxis: {
      categories: ['MEASLES-1/MR-1', 'MEASLES-2/MR-2'],
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: ['#4FC0C0', '#FF6384'],
  };

  const chartSeries = [
    {
      name: 'Vaccinated',
      data: [10367, 7475],
    },
    {
      name: 'Non-vaccinated',
      data: [12335, 3350],
    },
  ];
  return (
    <div style={{ marginTop: 20 }}>
      <SubHeading text="Received vs Not Received Vaccine " />
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default IdentifyHorizontalBarChart;
