import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const EnquiryReport = ({hesitantList}) => {

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>CHILDMPID</TableCell>
            <TableCell>District</TableCell>
            <TableCell>Village</TableCell>
            <TableCell>Vaccine</TableCell>
            <TableCell>Call Status</TableCell>
            <TableCell>Reason for not vaccinating</TableCell>
            {/* <TableCell>Confirm missed vaccine count</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {hesitantList?.map((item, index) => (
            <TableRow key={index} sx={index % 2 === 0 ? { bgcolor: '#f5f5f5' } : {}}>
              <TableCell>{item.child_mpid}</TableCell>
              <TableCell>{item.district_name}</TableCell>
              <TableCell>{item.village_name}</TableCell>
              <TableCell>{item.vaccinationrecord_set?.vaccine?.vaccine_name}</TableCell>
              <TableCell>{item.call_status?.status}</TableCell>
              <TableCell>{item.remark?.remark_not_vaccinating}</TableCell>
              {/* <TableCell>{item.count}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EnquiryReport;
