import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AreaChart = ({ chartData }) => {

  chartData.sort((a, b) => a.year - b.year);
  const years = chartData.map(item => item.year);
  const percentages = chartData.map(item => (item.average_percentage_overall));

  const chartOptions = {
    chart: {
      id: 'area-chart'
    },
    xaxis: {
      categories: years
    },
    yaxis: {
      min: 5,
      max: Math.max(...percentages) 
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    }
  };

  const chartSeries = [
    {
      name: 'Percentage',
      data: percentages
    }
  ];

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="area"
        height={450}
      />
    </div>
  );
};

export default AreaChart;
