import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart = ({ xAxisData, yAxisData }) => {

  const chartOptions = {
    chart: {
      id: 'bar-chart',
    },
    xaxis: {
      categories: xAxisData,
      labels: {
        style: {
          whiteSpace: 'hidden',
          fontSize: '12px',
        
          overflow: 'visible',
        },
      },
    },
  };

  const chartSeries = [
    {
      name: 'Series 1',
      data: yAxisData,
    },
  ];

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={400}
      />
    </div>
  );
};

export default BarChart;
