import React from 'react';
import { Card, CardContent, Grid, Typography, Button } from '@mui/material';
import happyChild from '../../assets/img/happychild.png'
import sadchild from '../../assets/img/sadchild.png'
import children from '../../assets/img/children.png'

const IdentifyCards = () => {

  return (


    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={children}
            alt="Icon"
            style={{ width: 50, marginRight: 10 }}
          />
          <div>
            <Typography variant="h6">22702</Typography>
            <Typography variant="body1">Children</Typography>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={happyChild}
            alt="Icon"
            style={{ width: 50, marginRight: 10 }}
          />
          <div>
            <Typography variant="h6">17833</Typography>
            <Typography variant="body1">Received</Typography>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={sadchild}
            alt="Icon"
            style={{ width: 50, marginRight: 10 }}
          />
          <div>
            <Typography variant="h6">4869</Typography>
            <Typography variant="body1">Not Received</Typography>
          </div>
        </CardContent>
      </Card>
    </div>


  );
};

export default IdentifyCards;
