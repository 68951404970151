import React from 'react';
import ReactWordcloud from 'react-wordcloud';

const WordCloudComponent = () => {
  // Sample data representing states and population of India
  const data = [
    { text: 'Jhapadipadla', value: 199812341 },
    { text: 'Badokhar', value: 112374333 },
    { text: 'Khadiyahar', value: 104099452 },
    { text: 'Dohara', value: 91276115 },
    { text: 'Matli', value: 72626809 },
    { text: 'Gobindpur', value: 63889011 },
    { text: 'Narayanganj', value: 52789563 },
    { text: 'Mukundapur', value: 49273642 },
    { text: 'Chak Bahram', value: 38294119 },
    { text: 'Rampur Jharkhand', value: 28502939 },
    { text: 'Bhupatipara', value: 17932874 },
    { text: 'Gopalganj', value: 14392745 },
    { text: 'Sonahar', value: 10284587 },
    { text: 'Kashipur', value: 8934576 },
    { text: 'Tatipaka', value: 7659283 },
    { text: 'Bhogri', value: 6590382 },
    { text: 'Kulharia', value: 5142839 },
    { text: 'Kurangapalem', value: 4729108 },
    { text: 'Mundrapada', value: 3928190 },
    { text: 'Bhawanipur', value: 3160928 },
    { text: 'Udaypur', value: 2793450 },
  ];
  

  return (
    <div style={{ width: '600px', height: '400px' }}>
      <ReactWordcloud words={data} />
    </div>
  );
};

export default WordCloudComponent;
