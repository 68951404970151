import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, CardContent, Card, Grid } from '@mui/material';

import CardSubHeading from './CardSubHeading'


const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  card: {
    minWidth: 200, // Updated width value (adjust as needed)
    margin: '1rem',
    textAlign: 'center',
  },
});

const MoniterCards = () => {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Card className={classes.card}>
        <CardSubHeading text=" Total(Not vaccinated)" />
        <CardContent>

          <Typography variant="h6" component="h2">
            50836
          </Typography>

        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardSubHeading text="Total child count" />
        <CardContent>
          <Typography variant="h6" component="h2">
            42340
          </Typography>

        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardSubHeading text="Confirmed missed vaccinees" />
        <CardContent>
          <Typography variant="h6" component="h2">
            1744
          </Typography>

        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardSubHeading text="vaccinated count" />
        <CardContent>
          <Typography variant="h6" component="h2">
            585
          </Typography>

        </CardContent>
      </Card>

    </div>
  );
};

export default MoniterCards;
