import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Header from '../layouts/Header';
import {
  AppBar, Toolbar, Typography, CardContent, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
  TableRow
} from '@mui/material';
import CardSubHeading from './components/CardSubHeading';
import json from '../assets/MadhyaPradesh.json';
import { MapContainer as Map, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Service } from './AppService';
import { ApiUrl } from './ApiConstant';
export default function Literacy() {
  const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [literacy, setliteracy] = useState(null);
  const [coloumn, setColoumn] = useState([]);
  const [rowdata, setRowdata] = useState([]);

  const getliteracy = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.LITERACY_GET_ALL);
      if (response) {
        const keys = Object.keys(response[0]);
        let coloumn = [];
        keys.map(e => {
          let data = {
            id: e,
            label: capitalize(e.split('_').join(' ')),
            minWidth: 100
          }
          coloumn.push(data)
        });

        setliteracy(response);
        setColoumn(coloumn);
        setRowdata(response)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  const capitalize = str => str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
  useEffect(() => {
    if (!hasLoaded) {
      getliteracy();
    }
  }, [hasLoaded]);
  function onFeature(data, layer) {
    layer.bindPopup(data.properties.NAME_2, { closeButton: false });
    layer.on({
      click: (e) => {
        console.log('e', e);
      },
      mouseover: (e) => {
        // e.target.setStyle({
        //   color: "green",
        //   fillColor: "yellow"
        // });
        layer.openPopup();
      },
      mouseout: (e) => {
        layer.closePopup();
        // console.log('e', e);
        // e.target.setStyle(confirmedStyle(e.sourceTarget.feature))
      }
    })
  }
  json.features.forEach(element => {
    element.properties.missed = Math.floor(Math.random() * 99)
  });
  function confirmedStyle(feature) {
    const r = rowdata.find(e=>e.district_name == feature.properties.NAME_2);
    if(!r) return;
    var confirmed = r?.literacy.replace('%','')
    if (confirmed < 25) {
      return {
        fillColor: "red",
        color: 'gray',
        weight: 2
      }
    } else if (confirmed < 50) {
      return {
        fillColor: "blue",
        color: 'gray',
        weight: 2
      }
    } else if (confirmed < 75) {
      return {
        fillColor: "green",
        color: 'gray',
        weight: 2
      }
    } else if (confirmed < 100) {
      return {
        fillColor: "yellow",
        color: 'gray',
        weight: 2
      }
    }
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
          <Header enabled={true} edit={false} chartType={true} filterIcon={true} title={"MADHYA PRADESH LITERACY VS NOT VACCINATED ANALYSIS"} />
        </Toolbar>
      </AppBar>
      <Grid style={{ margin: "1%" }} container spacing={1}>
        <Grid item xs={8}>
          <Card>
            <CardContent>
              <CardSubHeading text="Confirm Missed Vaccinees, Population and Literacy % by District Name" />
              <Map style={{ height: '80vh', background: 'white' }} center={[24, 78.6569]} zoom={6.5} scrollWheelZoom={false}>
                <GeoJSON data={json.features} onEachFeature={onFeature} style={confirmedStyle} />
              </Map>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <CardSubHeading text="Population data district wise" />
              <TableContainer sx={{ maxHeight: '80vh' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {coloumn.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowdata
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row,i) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {rowdata?.length && coloumn.map((column) => {
                              const value = rowdata && rowdata[i+page][column.id];
                              return (
                                <TableCell key={column.id} align={column?.align}>
                                  {column?.format && typeof value === 'number'
                                    ? column?.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rowdata.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </div>
  )
}
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  card: {
    minWidth: 200, // Updated width value (adjust as needed)
    margin: '1rem',
    textAlign: 'center',
  },
});
