import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Header from '../layouts/Header';
import { AppBar, Toolbar, Typography, CardContent, Card, Grid, Button } from '@mui/material';
import Barchart from '../scenes/chartTypes/BarChart';
import LineBar from '../scenes/chartTypes/LineBar';
import DonutChart from '../scenes/chartTypes/DonutChart';
import SubHeading from './components/SubHeading'
import CardSubHeading from './components/CardSubHeading'
import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFilter, } from '@fortawesome/free-solid-svg-icons';
import Loader from './components/Loader';
import { ApiUrl } from './ApiConstant';
import { Service } from './AppService';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  card: {
    minWidth: 200, // Updated width value (adjust as needed)
    margin: '1rem',
    textAlign: 'center',
  },
});

const Trend = () => {
  const classes = useStyles();
  const vaccineNames = ['MR', 'OPV', 'DPT', 'MEASLES OUTBREAK']
  const vaccinePercentage = [40, 281, 256, 8]

  const [weekData, setWeekData] = useState(null);
  const [weekTotalCountData, setWeekTotalCountData] = useState(null);

  const [vaccineData, setVaccineData] = useState(null);
  const [vaccineTotalCountData, setVaccineTotalCountData] = useState(null);

  const [weekVaccineData, setWeekVaccineData] = useState(null);
  const [vaccinatedCount, setVaccinatedCount] = useState(null);
  const [nonVaccinatedCount, setNonVaccinatedCount] = useState(null);

  const [hasLoaded, setHasLoaded] = useState(false);
  //Trend week Wise Success api
  const getWeekWiseSuccess = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.TREND_WEEK_WISE_SUCCESS_COUNT);
      if (response) {
       // setWeekWiseData(response);
       
          const weeks = response.map(item => item.week);
          const totalCounts = response.map(item => item.total_count);
          setWeekData(weeks);
          setWeekTotalCountData(totalCounts);
   
        
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getWeekWiseSuccess();
    }
  }, [hasLoaded]);


  // Extract weeks and total counts
  // Check if weekWiseSuccess is null or undefined before using it



  //Trend Vaccine Wise Success api
  const getVaccineWiseSuccess = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.TREND_VACCINE_WISE_SUCCESS_COUNT);
      if (response) {
        const vaccines = response.map(item => item.vaccine_name);
        const receivedCount = response.map(item => item.received_count);
        setVaccineData(vaccines);
        setVaccineTotalCountData(receivedCount);
 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getVaccineWiseSuccess();
    }
  }, [hasLoaded]);

  // week wise - vaccine wisw success count api
  const getWeekVaccineWiseSuccess = async () => {
    try {
      const response = await Service.Appservice('get', ApiUrl.TREND_WEEK_WISE_VACCINE_WISE_COUNT);
      if (response) {
        const week = response.map(item => item.week);
        const vaccinatedCount = response.map(item => item.vaccinated_count);
        const notVaccinatedCount = response.map(item => item.not_vaccinated_count);
        setWeekVaccineData(week);
        setVaccinatedCount(vaccinatedCount);
        setNonVaccinatedCount(notVaccinatedCount);
 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setHasLoaded(true);
    }
  };
  useEffect(() => {
    if (!hasLoaded) {
      getWeekVaccineWiseSuccess();
    }
  }, [hasLoaded]);

  //Export to PPT
  const trendBarChartRef = useRef(null);
  const trendDonutChartRef = useRef(null);
  const trendLineBarChartRef = useRef(null);

  const handleConvertToImage = () => {
    setLoading(true);

    const promises = [];

    let trendBarChartImage = null;
    let trendDonutChartImage = null;
    let trendLineBarChartImage = null;



    promises.push(
      html2canvas(trendBarChartRef.current).then((canvas1) => {
        trendBarChartImage = canvas1.toDataURL('image/png');
      })
    );

    promises.push(
      html2canvas(trendDonutChartRef.current).then((canvas2) => {
        trendDonutChartImage = canvas2.toDataURL('image/png');
      })
    );
    promises.push(
      html2canvas(trendLineBarChartRef.current).then((canvas2) => {
        trendLineBarChartImage = canvas2.toDataURL('image/png');
      })
    );



    Promise.all(promises).then(() => {
      downloadPptx(trendBarChartImage, trendDonutChartImage, trendLineBarChartImage);
    });
  };

  const [slideTitle, setSlideTitle] = useState(`Trend Analysis`);
  const [slideContent, setSlideContent] = useState("");
  const [loading, setLoading] = useState(false);
  const downloadPptx = (trendBarChartImage, trendDonutChartImage, trendLineBarChartImage) => {
    const pptxy = new PptxGenJS();

    const slide0 = pptxy.addNewSlide({ sectionTitle: slideTitle });
    slide0.addText(slideTitle, { x: 3.5, y: 2.5, fontSize: 24 });

    if (trendBarChartImage) {
      const slide1 = pptxy.addNewSlide({ sectionTitle: "slide 1" });
      slide1.addImage({ x: 2, y: 1, w: 6.5, h: 3.5, data: trendBarChartImage });
    }

    if (trendDonutChartImage) {
      const slide2 = pptxy.addNewSlide({ sectionTitle: "Slide 2" });
      slide2.addImage({ x: 2, y: 1, w: 5.5, h: 3, data: trendDonutChartImage });
    }

    if (trendLineBarChartImage) {
      const slide3 = pptxy.addNewSlide({ sectionTitle: "Slide 3" });
      slide3.addImage({ x: 1, y: 1, w: 8.5, h: 3, data: trendLineBarChartImage });
    }


    pptxy.writeFile({ fileName: `trend.pptx` }).then(() => {
      setLoading(false);
    });
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
          <Header enabled={true} edit={false} chartType={true} filterIcon={true} title={"Trend Analysis"} />
          <Button
            onClick={handleConvertToImage}
            id="filterButton"
            color="primary"
            style={{
              border: 'none',
              background: 'transparent',
              color: '#fff',
              marginLeft: '0%'
            }}
          >
            <FontAwesomeIcon size="lg" icon={faFileArrowDown} />
          </Button>
          {loading && (
            <div className="loader">
              <Loader text="Generating PPT file" />
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Card className={classes.card}>
        <CardSubHeading text=" Total(Not vaccinated)" />
        <CardContent>

          <Typography variant="h6" component="h2">
            50836
          </Typography>

        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardSubHeading text="Total child count" />
        <CardContent>
          <Typography variant="h6" component="h2">
            42340
          </Typography>

        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardSubHeading text="Confirmed missed vaccinees" />
        <CardContent>
          <Typography variant="h6" component="h2">
            1744
          </Typography>

        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardSubHeading text="vaccinated count" />
        <CardContent>
          <Typography variant="h6" component="h2">
            585
          </Typography>

        </CardContent>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card style={{ height: "530px" }} className={classes.card}>

            <CardContent>
              <div ref={trendBarChartRef}>
                <SubHeading text=" Week wise success count" />
                {hasLoaded ? (
                  weekData && weekTotalCountData && (

                    <Barchart xAxisData={weekData} yAxisData={weekTotalCountData} />

                  )
                ) : (
                  <Loader text="Loading..." />
                )}

              </div>

            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ height: "530px" }} className={classes.card}>

            <CardContent style={{ padding: '12%' }}>
              <div ref={trendDonutChartRef}>
                <SubHeading text=" Vaccine wise success count" />
              
                {hasLoaded ? (
                  vaccineData && vaccineTotalCountData && (

                    <DonutChart labelsData={vaccineData} seriesData={vaccineTotalCountData} />

                  )
                ) : (
                  <Loader text="Loading..." />
                )}
              </div>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid style={{ height: "530px" }} container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.card}>

            <CardContent>
              <div ref={trendLineBarChartRef}>
                <SubHeading text=" Week  wise success count vs total number of not vaccinated count" />
                {hasLoaded ? (
                  weekVaccineData && nonVaccinatedCount && vaccinatedCount && (

                    <LineBar category={weekVaccineData} lineData={nonVaccinatedCount} barData={vaccinatedCount} />

                  )
                ) : (
                  <Loader text="Loading..." />
                )}
            
              </div>

            </CardContent>
          </Card>

        </Grid>
      </Grid>
    </div>
  );
};

export default Trend;
