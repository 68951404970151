import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Typography, CardContent, Card, Grid, Button } from '@mui/material';
import Header from '../layouts/Header';
import IdentifyHorizontalBarChart from './components/IdentifyHorizontalBarChart';
import IdentifyCards from './components/IdentifyCards';
import IdentifyGeoChart from './components/IdentifyGeoChart';
import MoniterCards from './components/MoniterCards';
import MoniterBarChart from './components/MoniterBarChart';
import MoniterDonutChart from './components/MoniterDonutChart';
import MoniterHorizontalBarChart from './components/MoniterHorizontalBarChart';
import ReachSankeyChart from './components/ReachSankeyChart';
import ReachBarChart from './components/ReachBarChart';
import Advocate from './components/Advocate';
import MainHeading from './components/MainHeading';
import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faFilter, } from '@fortawesome/free-solid-svg-icons';
import Loader from './components/Loader';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: "#e9ecef"
  },
  card: {
    minWidth: 275,
    margin: '1rem',
    textAlign: 'center',
  },
  appBar: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
});

const Dashboard = () => {
  const classes = useStyles();


  //Export to PPT
  //const identifyGeoMapChartRef = useRef(null);
  const identifyHorizontalBarChartRef = useRef(null);
  const monitorBarChartRef = useRef(null);
  const monitorDonutChartRef = useRef(null);
  const monitorHorizontalBarChartRef = useRef(null);

  const handleConvertToImage = () => {
    setLoading(true);

    const promises = [];
   // let identifyGeoMapChartImage = null;
    let identifyHorizontalBarChartImage = null;
    let monitorBarChartImage = null;
    let monitorDonutChartImage = null;
    let monitorHorizontalBarChartImage = null;
    let Image = null;


    // promises.push(
    //   html2canvas(identifyGeoMapChartRef.current).then((canvas1) => {
    //     identifyGeoMapChartImage = canvas1.toDataURL('image/png');
    //   })
    // );

    promises.push(
      html2canvas(identifyHorizontalBarChartRef.current).then((canvas2) => {
        identifyHorizontalBarChartImage = canvas2.toDataURL('image/png');
      })
    );
    promises.push(
      html2canvas(monitorBarChartRef.current).then((canvas2) => {
        monitorBarChartImage = canvas2.toDataURL('image/png');
      })
    );
    promises.push(
      html2canvas(monitorDonutChartRef.current).then((canvas2) => {
        monitorDonutChartImage = canvas2.toDataURL('image/png');
      })
    );
    promises.push(
      html2canvas(monitorHorizontalBarChartRef.current).then((canvas2) => {
        monitorHorizontalBarChartImage = canvas2.toDataURL('image/png');
      })
    );



    Promise.all(promises).then(() => {
      downloadPptx(identifyHorizontalBarChartImage, monitorBarChartImage, monitorDonutChartImage, monitorHorizontalBarChartImage);
    });
  };

  const [slideTitle, setSlideTitle] = useState(`ZERO DOSE/DROP OUT CHILDREN SURVEILLENCE SYSTEM `);
  const [slideContent, setSlideContent] = useState("");
  const [loading, setLoading] = useState(false);
  const downloadPptx = (identifyHorizontalBarChartImage, monitorBarChartImage, monitorDonutChartImage, monitorHorizontalBarChartImage) => {
    const pptxy = new PptxGenJS();

    const slide0 = pptxy.addNewSlide({ sectionTitle: slideTitle });
    slide0.addText(slideTitle, { x: 1.5, y: 2.5, fontSize: 22 });

    if (identifyHorizontalBarChartImage) {
      const slide1 = pptxy.addNewSlide({ sectionTitle: "slide 1" });
      slide1.addImage({ x: 2, y: 1, w: 6, h: 2.5, data: identifyHorizontalBarChartImage });
    }

    // if (identifyGeoMapChartImage) {
    //   const slide2 = pptxy.addNewSlide({ sectionTitle: "Slide 2" });
    //   slide2.addImage({ x: 1, y: 1, w: 8.5, h: 3, data: identifyGeoMapChartImage });
    // }

    if (monitorBarChartImage) {
      const slide3 = pptxy.addNewSlide({ sectionTitle: "Slide 3" });
      slide3.addImage({ x: 2, y: 1, w: 4.5, h: 3.5, data: monitorBarChartImage });
    }
    if (monitorDonutChartImage) {
      const slide4 = pptxy.addNewSlide({ sectionTitle: "Slide 4" });
      slide4.addImage({ x: 2, y: 1, w: 6, h: 3, data: monitorDonutChartImage });
    }
    if (monitorHorizontalBarChartImage) {
      const slide5 = pptxy.addNewSlide({ sectionTitle: "Slide 5" });
      slide5.addImage({ x: 1, y: 1, w: 8.5, h: 3, data: monitorHorizontalBarChartImage });
    }

    pptxy.writeFile({ fileName: `identify.pptx` }).then(() => {
      setLoading(false);
    });
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
          <Header enabled={true} edit={false} chartType={true} filterIcon={true} title={"Zero Dose/ Dropout Children Surveillence System"} />
          <Button
            onClick={handleConvertToImage}
            id="filterButton"
            color="primary"
            style={{
              border: 'none',
              background: 'transparent',
              color: '#fff',
              marginLeft: '0%'
            }}
          >
            <FontAwesomeIcon size="lg" icon={faFileArrowDown} />
          </Button>
          {loading && (
            <div className="loader">
              <Loader text="Generating PPT file" />
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Grid style={{ margin: "1%" }} container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <MainHeading text="IDENTIFY" style={{ marginRight: '10px' }} />

              </div>
              <div style={{ width: '100%', margin: '20px', }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <IdentifyCards />
                    <div ref={identifyHorizontalBarChartRef}> <IdentifyHorizontalBarChart /> </div>

                  </Grid>
                  <Grid item xs={6}>
                    <div >  <IdentifyGeoChart /></div>

                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid style={{ margin: "1%" }} container spacing={1}>

        <Grid item xs={8}>
          <Card>
            <CardContent>
              <div style={{ width: '100%', margin: '20px', }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MainHeading text="MONITER, MEASURE AND LEARN" />
                    <MoniterCards />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div ref={monitorBarChartRef}>  <MoniterBarChart /></div>
                    </Grid>
                    <Grid item xs={6}>
                      <div ref={monitorDonutChartRef}><MoniterDonutChart /></div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div ref={monitorHorizontalBarChartRef}><MoniterHorizontalBarChart /></div>
                  </Grid>
                </Grid>
              </div>

            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <MainHeading text="REACH" />

              <ReachSankeyChart />
              <ReachBarChart />
              
            </CardContent>
          </Card>
        </Grid>

      </Grid>
      <Grid style={{ margin: "1%" }} container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <MainHeading text="ADVOCATE AND RESULT" />

              <Advocate />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
