import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({labelsData,seriesData}) => {
  const chartOptions = {
    series: seriesData,
    options: {
      chart: {
        type: 'donut',
      },
      labels: labelsData,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <Chart
        options={chartOptions.options}
        series={chartOptions.series}
        type="donut"
        width={450}
      />
    </div>
  );
};

export default DonutChart;
