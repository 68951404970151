import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, CardContent, Card, Grid } from '@mui/material';
import BarChartHorizontal from '../chartTypes/BarChartHorizontal';
import DonutChart from '../chartTypes/DonutChart';
import LineChart from '../chartTypes/LineChart';
import GenderChart from '../chartTypes/GenderChart';
import WordCloud from '../chartTypes/WordCloud';
import SubHeading from './SubHeading'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  card: {
    minWidth: 200, // Updated width value (adjust as needed)
    margin: '1rem',
    textAlign: 'center',
  },
});

const Advocate = () => {
  const classes = useStyles();
  const vaccineNames =  [ 'NO','YES',]
  const vaccinePercentage =  [4.26,95.74, ]
  return (
    <div style={{ height: "auto" }} className={classes.root}>


      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card style={{ height: "613px" }} className={classes.card}>
            <CardContent style={{  padding:'1%' }}>
              <SubHeading text="Follow-up Vaccinated Child by Year, Month, Day" />

              <LineChart />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ height: "613px" }} className={classes.card}>
            <CardContent>
              <SubHeading text="Vaccine Wise Vaccinated Count" />

              <GenderChart />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card style={{ height: "413px" }}  className={classes.card}>
            <CardContent  style={{  padding:'5%' }}>
              <SubHeading text="Vaccineation Success Percentage After Follow-up" />

              <DonutChart labelsData={vaccineNames} seriesData={vaccinePercentage} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ height: "413px" }} className={classes.card}>
            <CardContent>
              <SubHeading text="Day wise vaccinated count" />

              <WordCloud />
            </CardContent>
          </Card>
        </Grid>

      </Grid>

    </div>
  );
};

export default Advocate;
